import { filteredGetBuilder } from "./response";

export interface NewsletterSubscriptionDto {
  id: number;
  email: string;
  created: string | Date;
  modified?: string | Date | null;
  active: number | boolean;
}

export const newsletterSubscriptions = filteredGetBuilder<NewsletterSubscriptionDto>(`backend/newsletter-subscriptions`);

export const newsletterSubscriptionsForEmail = (email: string) => {
  return newsletterSubscriptions({ email }, { itemsPerPage: 10, page: 1, sortBy: [], sortDesc: [] });
}
