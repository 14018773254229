import { MediaDto } from "@/types/media";
import { apiHttp } from "../api-http";
import { Response, filteredGetBuilder } from "./response";
import { ContactDto } from "./contacts";

export enum ProductAvailability {
  temporary = 'RT',
  definitive = 'RD',
}

export enum ProductShippingAvailability {
  notAvailable = 0,
  available = 1,
  shippingOnRequest = 2,
  // productOnRequest = 3,
}

export interface ProductDto {
  id: number;
  doc_id: number;
  item_id: number;
  name: string | null;
  gtin: string | null;
  quantity: number | null;
  price: number | null;
  tax: number | null;
  total: number | null;
  label: string | null;
  to_doc_id: number | null;
  supplier_id: number | null;
  created: string | Date | null;
  modified: string | Date | null;
  from_doc_line_id: number | null;
  eco_part: number | null;
  total_eco_part: number | null;
  display_order: number | null;
  supplier_name: string | null;
  line_supplier_id: number | null;
  line_supplier_name: string | null;
  image: string | null;
  price_sell: number | null,
  price_buy: number | null;
  price_supplier: number | null,
  slug: string | null;
  reference: string | null;
  reference_slug: string | null;
  brand_item_reference: string | null;
  parent_id: number | null;
  product_eco_part: null,
  is_kit: boolean | number | null;
  packaging_type: string | null;
  packaging_quantity: number | null;
  packaging_unit: string | null;
  degressive_shipping_costs: number | null;
  availability: ProductAvailability | null;
  active: boolean;
  vat_rate: number | null;
  description: string | null;
  vote: number | null;
  vote_count: number | null;
  vote_product_id: number | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  image_flux: string | null;
  price_sell_modified: string | Date | null;
  price_buy_modified: string | Date | null;
  auto_margin: number | null;
  category_id: number | null;
  price_state: number | PriceStateEnum,
}

type ProductDtoAugmented = ProductDto & {
  editor_id?: number;
  editor?: ContactDto;
  medias?: MediaDto[];
};

export enum PriceStateEnum {
  contactUs = 'NC',
  shortage = 'PEN',
}

export const products = filteredGetBuilder<ProductDto>(`backend/items`);

export const product = async (productId: number | string) => {
  const res = await apiHttp.get(`backend/items/${productId}`).json<Response<ProductDtoAugmented>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};
