import { Response } from ".";
import { apiHttp } from "../api-http";

export interface SalesStatsDto {
  day: number,
  month: number,
  ytd: number,
  daySales: number,
  monthSales: number,
  ytdSales: number,
  year: number,
  dayAverage: number,
  monthAverage: number,
  yearSales: number,
  daySalesAverage: number,
  monthSalesAverage: number,
}

export const get = async () => {
  const res = await apiHttp.get(`backend/sales/stats`).json<Response<SalesStatsDto>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
};