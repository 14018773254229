import { PageStateFilters, PageStateOptions } from "@/store/page-state";
import { apiHttp } from "../api-http";
import { SearchParamsOption } from "ky";

export interface Error {
  type: string;
  description: string;  
}

export interface ErrorData {
  error?: boolean;
  message?: string;
  errors?: Record<string, string>;
}

export interface Response<T> {
  statusCode: number;
  data: T & ErrorData;
  error?: Error;
}

export interface FilteredList<T> {
  data: T[],
  count: number
}

export interface ResponseList<T> extends Response<FilteredList<T>> {

}

export function filteredGetBuilder<T>(url: string) {
  return (filters: PageStateFilters, options: PageStateOptions, searchParams: Record<string, string | number | boolean> | null = null) => filteredGet<T>(url, filters, options, searchParams);
}

export function filteredGetEnhancedBuilder<T, U>(url: string) {
  return (filters: PageStateFilters, options: PageStateOptions, searchParams: Record<string, string | number | boolean> | null = null) => filteredGetEnhanced<T, U>(url, filters, options, searchParams);
}

export async function filteredGet<T>(url: string, filters: PageStateFilters, options: PageStateOptions, searchParams: Record<string, string | number | boolean> | null = null) {
  const res = await apiHttp.get(url, {
    searchParams: {
      filters: JSON.stringify(filters),
      options: JSON.stringify(options),
      ...(searchParams ?? {}),
    } as SearchParamsOption
  }).json<ResponseList<T>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
}

export async function filteredGetEnhanced<T, U>(url: string, filters: PageStateFilters, options: PageStateOptions, searchParams: Record<string, string | number | boolean> | null = null) {
  const res = await apiHttp.get(url, {
    searchParams: {
      filters: JSON.stringify(filters),
      options: JSON.stringify(options),
      ...(searchParams ?? {}),
    } as SearchParamsOption
  }).json<Response<FilteredList<T> & U>>();
  if (res.error) {
    throw new Error(`${res.error.type}: ${res.error.description}`);
  }
  return res.data;
}