<template>
  <div>
    <v-app-bar app clipped-left clipped-right dark fixed :color="toolbarColor">
      <v-app-bar-nav-icon v-if="authUser" @click="$emit('nav-clicked')"></v-app-bar-nav-icon>
      <v-toolbar-title>Prix de gros</v-toolbar-title>
      <v-spacer></v-spacer>
      <router-link v-for="user in realtimeUsers" :key="`avatar-${user.username}`" class="mr-2" :to="user.location">
        <v-avatar :color="`#${user.color}`" size="36" :style="{ opacity: user.samePage || user.sameData ? '100%' : '20%' }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img v-if="user.logo" v-bind="attrs" :src="logoSrcContact(user.id)" alt="" v-on="on">
              <span v-else v-bind="attrs" class="white--text" v-on="on">{{ acronym(`${user.first_name} ${user.last_name}`) }}</span>
            </template>
            <span>{{ user.title }}</span>
          </v-tooltip>
        </v-avatar>
      </router-link>
      <v-btn icon href="https://uptime.digital-balloon.work/status/prix-de-gros" target="_blank">
        <v-icon aria-hidden="true">{{ mdiProgressWrench }}</v-icon>
      </v-btn>
      <v-btn v-if="isEmployee" icon href="https://app.affine.pro/workspace/700dbad3-272e-4e85-a583-f788456f1575/all" target="_blank">
        <v-icon aria-hidden="true">{{ mdiBookOpenVariant }}</v-icon>
      </v-btn>
      <v-btn v-if="isEmployee" icon @click="$router.push(i18nRoute({ name: 'task' }))">
        <v-badge :content="meTasksCounter?.count" color="orange" overlap :value="meTasksCounter?.count ?? false">
          <v-badge :content="meTasksCounter?.count_overdue" color="red" overlap :value="meTasksCounter?.count_overdue ?? false" left>
            <v-icon>{{ mdiBellOutline }}</v-icon>
          </v-badge>
        </v-badge>
      </v-btn>
      <template v-if="authUser">
        <v-btn icon title="Se déconnecter" @click.stop="signout">
          <v-icon aria-hidden="true">{{ mdiLocationExit }}</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-btn icon @click="$router.push(i18nRoute({ name: 'signin' }))">
          <v-icon aria-hidden="true">{{ mdiAccount }}</v-icon>
        </v-btn>
        <LanguageSwitcher />
      </template>
    </v-app-bar>
    <div v-for="user in samePageUsers" :key="`cursor-${user.username}`" style="width: 5px; height: 5px; position: absolute; border-radius: 100%; z-index: 100; top: 0; left: 0; box-shadow: grey 1px 1px 4px; transition: transform 500ms;" :style="{ backgroundColor: `#${user.color}`, /*top: `${user.y}%`, left: `${user.x}%`*/ transform: `translateY(${user.y}vh) translateX(${user.x}vw)`, visibility: user.username !== authUser?.username ? 'visible' : 'hidden' }"></div>
  </div>
</template>

<script setup lang="ts">
import { i18nRoute } from "@/plugins/Translation";
import { acronym } from "@/plugins/helper";
import { logoSrcContact } from "@/plugins/helper-image";
import { apiHttpUtils } from "@/services/api-http";
import { useMeTasksCounter } from "@/services/fetcher";
import { useAuthStore } from "@/store/auth";
import { useUsersStore } from "@/store/users";
import { mdiAccount, mdiBellOutline, mdiBookOpenVariant, mdiLocationExit, mdiProgressWrench } from "@mdi/js";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from 'vue-router/composables';
import LanguageSwitcher from "./LanguageSwitcher.vue";

defineEmits(['nav-clicked']);

const router = useRouter();
const authStore = useAuthStore();
const usersStore = useUsersStore();
const { user: authUser } = storeToRefs(authStore);
const { realtimeUsers } = storeToRefs(usersStore);

const { meTasksCounter } = useMeTasksCounter(authUser);

const isEmployee = computed(() => {
  return authUser.value ? (authUser.value.type ?? 0) & 1024 : false;
});

const signout = () => apiHttpUtils.auth.signOut().then(() => router.replace({ name: "home" }));
const toolbarColor = computed(() => process.env.VUE_APP_TOOLBAR_COLOR);
const samePageUsers = computed(() => realtimeUsers.value.filter(user => user.samePage));

</script>
