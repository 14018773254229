import Vue from "vue";
import { router } from "../router";
import axios from "axios";
import { useAuthStore } from "@/store/auth";
import { apiHttpUtils } from "@/services/api-http";

axios.interceptors.request.use(
  function (config) {
    const { token } = useAuthStore();

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ([401, 403].includes(error.response.status)) {
      const originalRequest = error.config;
      const { refreshToken } = useAuthStore();
      if (refreshToken && !originalRequest.url.match(/auth\/refresh$/)) {
        console.log('Refresh token available, trying to get a new token');
        return apiHttpUtils.auth.refreshAccessToken(refreshToken).then(() => {
          return axios(originalRequest)
        }).catch(err => Promise.reject(err));
      }

      return router.push({ name: "signin", query: { returnUrl: router.currentRoute.fullPath, ...router.currentRoute.query /* on prend le premier returnUrl */, disconnected: true } });
    }
    return Promise.reject(error);
  }
);

Vue.prototype.$axios = axios;
