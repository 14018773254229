import { AppNotification } from '@/types/app-notification';
import mitt from 'mitt';

const emitter = mitt();

export default {
  on: emitter.on,
  off: emitter.off,
  emit: emitter.emit,
  error: (err: unknown) => emitter.emit('error', err),
  success: (title: string, description?: string) => emitter.emit('notification', { title, description, type: 'success', timeout: 5000 } as AppNotification),
  info: (title: string, description?: string) => emitter.emit('notification', { title, description, type: 'info', timeout: 5000 } as AppNotification),
  notification: (notif: AppNotification) => emitter.emit('notification', notif),
};