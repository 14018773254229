import { apiHttp } from "../api-http";
import { Response } from "./response";

export interface DocOverrideDto {
  id: number;
  active: boolean | 1 | 0 | null;
}

export const add = async (data: Partial<DocOverrideDto>) => {
  return await apiHttp.post(`backend/doc-overrides`, { json: data }).json<Response<{ id: number }>>();
}

export const update = async (id: number, data: Partial<DocOverrideDto>) => {
  return await apiHttp.post(`backend/doc-overrides/${id}`, { json: data }).json<Response<{ id: number }>>();
}