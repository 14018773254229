import { ContactDto } from "@/services/api-pdg/contacts";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from "vue-router/composables";
import { useAuthStore } from "./auth";

export type ConnectedUser = ContactDto & { title: string, date: string, color: string, location: string, x: number, y: number, dataType: string, dataId: string, dataUpdate: boolean, };
export type ConnectedUserWithPage = ConnectedUser & { samePage: boolean, sameData: boolean };

export const useUsersStore = defineStore('users', () => {
  const route = useRoute();
  const authStore = useAuthStore();
  const { user: authUser } = storeToRefs(authStore);
  const users = ref<ConnectedUser[]>();
  const connectedUser = computed(() => users.value?.find(val => val.id === authUser.value?.id))

  const realtimeUsers = computed<ConnectedUserWithPage[]>(() => {
    return sortBy(users.value?.map((user) => {
      const dataMatch = user.location.match(/(\w+)\/(\d+)(?:\/update){0,1}$/);
      return {
        ...user,
        samePage: user.location === route.fullPath,
        sameData: dataMatch && dataMatch[1] === connectedUser.value?.dataType && dataMatch[2] === connectedUser.value?.dataId,
      } as ConnectedUserWithPage;
    }) ?? [], ['username']);
  });

  const userUpdatingSameData = computed(() => realtimeUsers.value.find((user) => user.id !== connectedUser.value?.id && user.sameData && user.dataUpdate));
  const userLookingAtSameData = computed(() => realtimeUsers.value.find((user) => user.id !== connectedUser.value?.id && user.sameData && !user.dataUpdate));

  function update(data: ConnectedUser) {
    const currentUsers = users.value?.filter((val) => val.username !== data.username && dayjs().diff(val.date, 'minutes') < 5) ?? [];
    currentUsers.push(data);

    users.value = currentUsers;
  }

  return {
    users,
    update,
    realtimeUsers,
    userUpdatingSameData,
    userLookingAtSameData,
  };
});
