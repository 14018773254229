
import { ContactDto } from '@/services/api-pdg/contacts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthStore = defineStore('auth', () => {
  const token = ref<string>();
  const refreshToken = ref<string>();
  const user = ref<ContactDto>();

  return {
    token,
    refreshToken,
    user,
  };
}, {
  persist: {
    paths: ['token', 'refreshToken', 'user'],
  }
});